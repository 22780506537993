import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import Container from 'components/common/Container';
import useUserCompany from 'hooks/useUserCompany';

// import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';

import Stack from 'components/common/Stack';
import api from 'constants/api';
import AppLoading from 'components/common/AppLoading';
import {
  getClimateScoreAsPercent,
  getClimateScoreCategoryScores,
} from 'utils/tools';

import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';

import Box from 'components/common/Box';
import CollectionOverviewList from './CollectionList';
import SelectMenu from 'components/common/SelectMenu';
import useUserCompanyCollections from 'hooks/useUserCompanyCollections';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/common/Button';
import Check from 'components/icons/Check';
import Download from '@mui/icons-material/Download';
import Link from 'components/common/Link';
import colors from 'constants/colors';
import { fontWeight } from 'constants/typography';
import LinearProgress from 'components/common/LinearProgress';
import CollectionWidgetCountries from './CollectionWidgetCountries';
import { ChevronRight } from '@mui/icons-material';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import useCompanySectors from 'hooks/useCompanySectors';
import Chip from 'components/common/Chip';
import WidgetCard from './CollectionCard';
import CollectionsUpsell from 'components/App/Routes/Collections/CollectionsUpsell';
import UserDashboardSuppliersStore from './store/UserDashboardSuppliersStore';
import SuppliersInviteCard from 'components/common/SuppliersInviteCard';

function DownloadSupplierCollectionData({
  collectionId,
  disabled,
}: {
  collectionId?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation('common');
  return (
    <Link
      href={
        disabled ? undefined : `${api.url}/user/company/suppliers/data_export`
      }
      rel="noopener"
      underline="none"
      target="_blank"
    >
      <Button disabled={disabled} type="button" startIcon={<Download />}>
        {t('Download Data')}
      </Button>
    </Link>
  );
}

function UserDashboardCollection() {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const {
    hasLoadedDashboardDataStatistics,
    isLoadingDashboardData,
    isLoadingDashboardDataStatistics,
    loadDashboardData,
    loadUserCompanyStatistics,
    hasLoadedDashboardData,
    user_company_suppliers_count_on_search,
    user_company_suppliers_results,
    user_company_suppliers_with_valid_crc,
    supplier_invite_link,
  } = UserDashboardSuppliersStore();
  const { userCompany } = useUserCompany();

  let page = searchParams.get('page') || '1';
  let search = searchParams.get('search') || '';
  const rowsPerPage = 100;

  useEffect(() => {
    if (!hasLoadedDashboardDataStatistics) {
      loadUserCompanyStatistics({ auth });
    }
    if (hasLoadedDashboardDataStatistics) {
      loadDashboardData({ auth, page, search, rowsPerPage });
    }
  }, [
    auth,
    hasLoadedDashboardDataStatistics,
    loadDashboardData,
    loadUserCompanyStatistics,
    page,
    search,
  ]);

  return (
    <Container pageContainer>
      {isLoadingDashboardData || isLoadingDashboardDataStatistics ? (
        <AppLoading />
      ) : (
        <Stack spacing={5}>
          <Stack spacing={1}>
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate('/collections')}
              >
                {t('Collections')}
              </Link>

              <ChevronRight color="inherit" sx={{ opacity: 0.15 }} />
              <Typography variant="body1" noWrap sx={{ opacity: 0.4 }}>
                {'My Suppliers'}
              </Typography>
            </Stack>
            <Stack
              direction={{ sm: 'column', md: 'row' }}
              spacing={1}
              alignItems={{ sm: 'flex-start', md: 'flex-end' }}
            >
              <Typography variant="h4">{'My Suppliers'}</Typography>
              <Box flex={1}></Box>

              <Box>
                <DownloadSupplierCollectionData />
              </Box>
            </Stack>
          </Stack>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  onClick={() => navigate('/collections/suppliers/screening')}
                >
                  {t('View Maturity Report')}
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Stack spacing={1}>
            <Typography variant="h5">{t('Companies')}</Typography>
            <CollectionOverviewList data={user_company_suppliers_results} />
          </Stack>
          {userCompany.supplier_invite_link ? (
            <SuppliersInviteCard
              title={t('Invite Your Suppliers')}
              suppliersInvitationLink={supplier_invite_link}
            />
          ) : null}
        </Stack>
      )}
    </Container>
  );
}

export default observer(UserDashboardCollection);
