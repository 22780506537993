import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Box from 'components/common/Box';
import List from '@mui/material/List';
import ListItem, { listItemClasses } from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
import colors from 'constants/colors';

import useAuth from 'hooks/useAuth';

function LinksTabWeb() {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const MyNavLink = React.forwardRef((props: any, ref) => {
    return <NavLink ref={ref} {...props} activeclassname="active" />;
  });

  return (
    <List
      sx={(theme) => ({
        ...theme.mixins.toolbar,
        padding: 0,
        display: 'flex',
        [`& .${listItemClasses.root}`]: {
          color:
            theme.palette.mode === 'dark' ? colors.white : colors.neutral500,
        },
        [`& .active`]: {
          color: colors.onSurface100,
          paddingTop: '10px',
          fontWeight: 'bold',
          borderBottom: `2px solid ${colors.brandYellow}`,
        },
      })}
    >
      {auth.hasDashboardPermission ? (
        <ListItem component={MyNavLink} key={1} to="/home">
          <ListItemText primary={`${t('Home')}`} />
        </ListItem>
      ) : null}
      {auth.hasDashboardPermission ? (
        <ListItem component={MyNavLink} key={2} to="/collections">
          <ListItemText
            primary={`${t('Collections')}`}
            sx={{ whiteSpace: 'nowrap' }}
          />
        </ListItem>
      ) : null}
    </List>
  );
}

export default observer(LinksTabWeb);
