import { observer } from 'mobx-react-lite';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import useUserCompany from 'hooks/useUserCompany';

import Grid from 'components/common/Grid/Grid';
import Typography from 'components/common/Typography/Typography';

import SuppliersInviteCard from 'components/common/SuppliersInviteCard';
import DashboardItems from './DashboardItems';
import Box from 'components/common/Box';
import { Alert } from '@mui/material';
import UserDashboardSuppliersStore from '../CollectionSupplier/store/UserDashboardSuppliersStore';
import { useEffect } from 'react';
import Container from 'components/common/Container';
import AppLoading from 'components/common/AppLoading';

function Component() {
  const auth = useAuth();
  const { t } = useTranslation('common');

  const {
    userCompany,
    loadUserCompany,
    hasLoadedUserCompany,
    isLoadingUserCompany,
  } = useUserCompany();

  //FIXME - this is until the backend updates the /user/company/
  //@ts-ignore
  const { suppliers_count, supplier_invite_link } = userCompany;

  useEffect(() => {
    if (!hasLoadedUserCompany && !isLoadingUserCompany) {
      loadUserCompany({ auth });
    }
  }, [auth, loadUserCompany, hasLoadedUserCompany, isLoadingUserCompany]);

  return isLoadingUserCompany ? (
    <AppLoading />
  ) : (
    <Container pageContainer>
      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h4" color="onSureface.100">
            {t('Supplier Maturity Screening')}
          </Typography>
        </Grid>

        {suppliers_count && suppliers_count > 0 ? (
          <Grid item xs={12}>
            <Box pb={4}>
              <DashboardItems />
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <SuppliersInviteCard
            title={t('Invite Your Suppliers')}
            suppliersInvitationLink={supplier_invite_link}
          />
        </Grid>
        {suppliers_count === 0 ? (
          <Grid item xs={12}>
            NO SUPPLIERS
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}

export default observer(Component);
