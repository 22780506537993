import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';

import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

import CollectionListDataGrid from './CollectionListDataGrid';
import { toJS } from 'mobx';
import { getClimateScoreAsPercent } from 'utils/tools';

function UserCompanyCollectionList({
  collectionId,
  hasCollections,
  data,
}: {
  collectionId?: string;
  hasCollections?: boolean;
  data: any[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rows = data.slice().map((d: any) => ({
    id: d.id,
    company_name: d.name,
    company_slug: d.slug,
    company_sector: d.sector,
    company_country: d.country,
    company_logo: d.logo,
    company_website: d.website,
    reporting_company_name: d.reporting_company_name,
    reporting_company_slug: d.reporting_company_slug,
    climate_score: d.climate_score,
    maturity_level:
      d.crc_result === 'Level 3'
        ? 'GOOD'
        : d.crc_result === 'Level 2'
        ? 'FAIR'
        : 'POOR',
    public_data_available: d.climate_score > 0,
    public_data_screening_last_date: d.last_screened,
  }));
  return <CollectionListDataGrid collectionId={collectionId} rows={rows} />;
}

export default UserCompanyCollectionList;
