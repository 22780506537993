import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import useUserCompany from 'hooks/useUserCompany';

// import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';

import CompanyInfo from './CompanyInfo';
import ClimateScore from './ClimateScore';
import CompanyDimensionBenchmark from './CompanyDimensionBenchmark';

import Stack from 'components/common/Stack';
import api from 'constants/api';
import AppLoading from 'components/common/AppLoading';
import EmissionsCalculationModule from './EmissionsCalculationModule';
import StrategyAndControlModule from './StrategyAndControlModule';
import TargetsModule from './TargetsModule';
import DecarbonizationMeasuresModule from './DecarbonizationMeasuresModule';
import EmissionsPerformanceModule from './EmissionsPerformanceModule';
import CPALink from './CPALink';
import CustomerBenchmark from './CustomerBenchmark';
import NextSteps from './NextSteps';
import WhatWasUsed from './WhatWasUsed';
import Contact from './Contact';
import { getClimateScoreCategoryScores } from 'utils/tools';
import Toolbox from './Toolbox';
import Box from 'components/common/Box';
import { useEffect } from 'react';
import Container from 'components/common/Container';

function UserDashboardScorecard() {
  const auth = useAuth();

  const { t } = useTranslation('common');
  const {
    userCompany,
    userCompanyCPALink,
    loadUserCompany,
    hasLoadedUserCompany,
    isLoadingUserCompany,
  } = useUserCompany();

  const { isLoading, data, isError } = useQuery({
    enabled: !!userCompany.id,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['scorecard', userCompany.id],
    retry: false,
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/scorecard/${userCompany.id}`,
        auth.requestConfig
      );
      return res.data as {
        scorecard: { overall: number; categories: { score: number }[] };
      };
    },
  });

  useEffect(() => {
    if (!isLoadingUserCompany && !hasLoadedUserCompany) {
      loadUserCompany({ auth });
    }
  }, [auth, isLoadingUserCompany, hasLoadedUserCompany, loadUserCompany]);

  const {
    emissionsCalculation,
    strategyAndControl,
    targets,
    decarbonizationMeasures,
    emissionsPerformance,
  } = getClimateScoreCategoryScores(data?.scorecard);

  const showCPALinkBanner = userCompanyCPALink.url;

  return (
    <Container pageContainer>
      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12}>
          {isLoading ? (
            <AppLoading />
          ) : isError ? (
            <Alert severity="error">{t('Something went wrong')}</Alert>
          ) : (
            <Grid container spacing={{ xs: 2, sm: 3 }}>
              {showCPALinkBanner ? (
                <Grid item xs={12}>
                  <CPALink
                    title={t(
                      'Your Climate Performance Assessment (valid for {{count}} days)',
                      { count: userCompanyCPALink.validity_period }
                    )}
                    link={userCompanyCPALink.url}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <CompanyInfo />
              </Grid>
              <Grid item xs={7}>
                <ClimateScore scorecard={data?.scorecard} />
              </Grid>
              <Grid item xs={5}>
                <CustomerBenchmark scorecard={data?.scorecard} />
              </Grid>
              <Grid item xs={12} md={4}>
                <NextSteps />
              </Grid>
              <Grid item xs={12} md={8}>
                <CompanyDimensionBenchmark scorecard={data?.scorecard} />
              </Grid>
              <Grid item xs={12}>
                <EmissionsCalculationModule score={emissionsCalculation} />
              </Grid>
              <Grid item xs={12}>
                <StrategyAndControlModule score={strategyAndControl} />
              </Grid>
              <Grid item xs={12}>
                <TargetsModule score={targets} />
              </Grid>
              <Grid item xs={12}>
                <DecarbonizationMeasuresModule
                  score={decarbonizationMeasures}
                />
              </Grid>
              <Grid item xs={12}>
                <EmissionsPerformanceModule score={emissionsPerformance} />
              </Grid>
              <Grid item xs={12}>
                <WhatWasUsed />
              </Grid>
              <Grid item xs={12}>
                <Contact
                  companyName={userCompany.slug}
                  id={userCompany.id}
                  userDetails={auth.userDetails}
                />
              </Grid>
              <Grid item xs={12}>
                <Box pt={8}>
                  <Toolbox />
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default observer(UserDashboardScorecard);
