import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';

import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

import CollectionsListDataGrid from './CollectionsListDataGrid';
import useUserCompanyCollections from 'hooks/useUserCompanyCollections';
import Stack from 'components/common/Stack';
import CollectionsUpsell from './CollectionsUpsell';
import UserDashboardSuppliersStore from '../CollectionSupplier/store/UserDashboardSuppliersStore';
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { observer } from 'mobx-react-lite';
import AppLoading from 'components/common/AppLoading';

function UserCompanyCollectionsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams] = useSearchParams();
  const auth = useAuth();

  const { isLoading, data } = useUserCompanyCollections();
  const {
    hasLoadedDashboardDataStatistics,
    isLoadingDashboardData,
    loadDashboardData,
    loadUserCompanyStatistics,
    hasLoadedDashboardData,
    user_company_suppliers_count_on_search,
    user_company_suppliers_results,
    user_company_suppliers_with_valid_crc,
    supplier_invite_link,
  } = UserDashboardSuppliersStore();

  useEffect(() => {
    if (!hasLoadedDashboardData && !isLoadingDashboardData) {
      loadDashboardData({ auth, page: '1', search: '', rowsPerPage: 100 });
    }
  }, [auth, hasLoadedDashboardData, isLoadingDashboardData, loadDashboardData]);

  const rows =
    data?.length > 0
      ? data.map((d: any) => ({
          id: d.id,
          name: d.name,
        }))
      : [];

  return isLoading || isLoadingDashboardData ? (
    <AppLoading />
  ) : (
    <Stack spacing={2}>
      <CollectionsListDataGrid
        rows={
          user_company_suppliers_results.length > 0
            ? [{ id: 'suppliers', name: 'My Suppliers' }].concat(rows)
            : rows
        }
        loading={isLoading || isLoadingDashboardData}
      />
      {!isLoading && data?.length === 0 ? <CollectionsUpsell /> : null}
    </Stack>
  );
}

export default observer(UserCompanyCollectionsList);
