import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Toolbar from 'components/common/Toolbar';
import IconButton from 'components/common/IconButton';
import Logo from 'components/icons/Logo';

import LinksTabWeb from './LinksTabWeb';
import AvatarUserSettings from './AvatarUserSettings';

import colors from 'constants/colors';

import LanguageSwitch from '../../LanguageSwitch';
import Stack from 'components/common/Stack';
import SearchIcon from '@mui/icons-material/SearchOutlined';

const Component = () => {
  return (
    <Toolbar disableGutters={false}>
      <Stack spacing={2} direction="row" width={'100%'} alignItems={'center'}>
        <Box sx={{ flexGrow: 1 }}>
          <Box
            component={Link}
            to={'/'}
            sx={{
              display: 'flex',
              cursor: 'pointer',
            }}
          >
            <Logo
              sx={{
                width: '90px',
                height: '60px',
                color: (theme: any) =>
                  theme.palette.mode === 'dark'
                    ? colors.white
                    : colors.onSurface100,
              }}
            />
          </Box>
        </Box>

        <Box sx={{ flexGrow: 0, display: 'flex' }} id={'TabsContainer'}>
          <LinksTabWeb />
        </Box>
        <IconButton sx={{ color: colors.neutral400 }}>
          <SearchIcon color="inherit" />
        </IconButton>
        <AvatarUserSettings />
      </Stack>
    </Toolbar>
  );
};
export default observer(Component);
